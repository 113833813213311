<template>
    <div class="about">
        <myhead :param="param" />
        <section>
            <div class="banneraabout">
                <div style="height:280px; "></div>
                <div style="font-size: 44px;font-weight: bold;color: #ffffff;">公司简介</div>
                <!--<div class="htable" style="font-size: 18px; color: rgba(255,255,255,0.8);margin-top: 40px;line-height: 32px; justify-content: center;">
                    <div class="hcell" style="width: 1200px;">
                    重庆辉腾能源股份有限公司（证券简称：辉腾能源，证券代码：872947）着眼全球清洁能源可持续发展，专注低日照弱光环境智能光伏
                    设备研发生产，提供从光伏产品到光伏应用的整体解决方案，不断开拓创新，引领光伏产业进步，用太阳能造福全人类。
                        </div>
                </div>
                <div class="htable" style="font-size: 18px; color: rgba(255,255,255,0.8);margin-top: 40px; justify-content: center;line-height: 32px;">
                    <span class="hcell" style="border: 1px solid rgba(255,255,255,0.8);padding: 20px;">
                        铸平台共创价值 用科技改变生活
                    </span>
                </div>-->
             </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="body1660 htable">
                <div class="hcell" style="width: 130px;text-align: left;">
                    <div><span class="btLine">辉腾</span>简介</div>
                </div>
                <div class="hcell" style="width: 426px;">
                    <div style="font-size: 24px;font-weight: bold;line-height: 36px;text-align: left;padding-right: 80px;" v-html="this.res.tb_title_sub">
                    <!--综合能源及智慧产品设计研发、<br/>
                        生产集成和运营服务的<br/>
                    一站式服务商-->
                    </div>
                </div>
                <div  class="hcell" style="width: 980px;">
                    <div style="font-size: 16px;line-height: 30px;text-align: left;margin-top: -20px;" v-html="this.res.tb_content">
                   <!-- 重庆辉腾能源股份有限公司着眼全球清洁能源可持续发展，专注低日照弱光环境智能光伏设备研发生产，提供从光伏产品到光伏应用的整体解决方案，不断开拓创新，
                        引领光伏产业进步，用太阳能造福全人类。
                        <p>
                            截至2020年底，公司光伏发电站销量突破1835座，高效智能太阳能路灯销量突破30万套，辉腾能源在全球布局下游生态链，为客户提供开发、融资、设计、
                            施工、运维等一站式系统集成解决方案。目前，辉腾能源正在向光伏智慧能源及能源互联网解决方案提供商迈进。</p>
                        <p>
                            公司现已拥有自主知识产权专利技术147项，软件著作权15项，国家及省部级资质荣誉100余项，全面通过GB/T29490知识产权管理体系、
                            ISO9001质量管理体系、OHSAS180001职业健康安全管理体系认证、ISO14001环境管理体系；公司具备电力总承包、机电总承包、城市道路照明专项承包等资质。
                            稳健踏实，追求卓越的辉腾能源，矢志成为一家持续创新、客户信赖的专业能源企业，为国家清洁能源发展和节能工程作出巨大贡献!</p>-->
                    </div>
                    <div>

                        <video-player  class="video-player vjs-custom-skin"
                                       id="playerOptions1"
                                       x-webkit-airplay="true"
                                       :playsinline="true"
                                       :options="playerOptions1"

                        >
                        </video-player>

                    </div>
                </div>
                <div  class="hcell" style="width: 130px;">

                </div>
            </div>
        </section>
        <section style="margin-top: 80px;">
            <div class="body1400"  style="display: flex;">
                <div class="zzcp" v-for="(n,i) of businessData.length " :key="'item'+businessData[i].tb_id">
                    <div class="zzcp1"  :style="i===(businessData.length-1)?'border-right: none;':'' " >
                        <div class="zzcp2">{{businessData[i].tb_title}}</div>
                        <div class="zzcp3">{{businessData[i].tb_summary}}</div>
                        <div class="zzcp2">{{businessData[i].tb_title_sub}}</div>
                    </div>
                </div>
                <!--
                <div class="zzcp" >
                    <div class="zzcp1">
                        &lt;!&ndash;:style="zzcpstyle"&ndash;&gt;
                        &lt;!&ndash;<img :src="linzzcp" style="border: 1px solid #d7000f;">&ndash;&gt;
                        <div class="zzcp2">拥有</div>
                        <div class="zzcp3">15</div>
                        <div class="zzcp2">项软件著作权</div>
                    </div>
                </div>
                <div class="zzcp">
                    <div class="zzcp1">
                        <div class="zzcp2">公司自主研发</div>
                        <div class="zzcp3">50</div>
                        <div class="zzcp2">多款产品</div>
                    </div>
                </div>
                <div class="zzcp">
                    <div class="zzcp1">
                        <div class="zzcp2">拥有</div>
                        <div class="zzcp3">147</div>
                        <div class="zzcp2">项专利证书</div>
                    </div>
                </div>
                <div class="zzcp">
                    <div class="zzcp1">
                        <div class="zzcp2">光伏发电站突破</div>
                        <div class="zzcp3">1385</div>
                        <div class="zzcp2">座</div>
                    </div>
                </div>
                <div class="zzcp">
                    <div class="zzcp1" style="border-right: none;">
                        <div class="zzcp2">太阳能路灯突破</div>
                        <div class="zzcp3">300000</div>
                        <div class="zzcp2">套座</div>
                    </div>
                </div>-->
            </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="xuesan">
                <div style="height: 140px;"></div>
                <div style="font-size: 36px;font-weight: bold;color: rgba(255,255,255,0.9)">发展理念</div>

            <div class="body1400 htable" style="margin-top: 70px;">

                <div class="hcell xuesantxt"  v-for="(n,i) of rowsIdeas.length " :key="'item'+rowsIdeas[i].tb_id" >
                    <div style="height: 76px;overflow: hidden"><img :src="rowsIdeas[i].tb_image"></div>
                    <div class="xuesantitl">{{rowsIdeas[i].tb_title}}</div>
                    <div class="xuesannote">{{rowsIdeas[i].tb_summary}}</div>
                </div>

<!--
                <div class="hcell xuesantxt"  >
                    <div><img :src="xs1"></div>
                    <div class="xuesantitl">依靠技术进步</div>
                    <div class="xuesannote">累计研发投入1000多万元；知识产权与专利技术170余项；资质荣誉150余项；自主知识产权技术国内领先。</div>
                </div>
                <div class="hcell xuesantxt" >
                    <div><img :src="xs2"></div>
                    <div class="xuesantitl">坚持创新驱动</div>
                    <div class="xuesannote">公司秉承创新是第一推进力理念，坚持经营理念、关键技术、产品研发、运营管理和商业模式持续创新升级。</div>
                </div>
                <div class="hcell xuesantxt" >
                    <div><img :src="xs3"></div>
                    <div class="xuesantitl">注重绿色环保</div>
                    <div class="xuesannote">立足绿色发展，秉承绿色环保、专业优质理念，专注低日照弱光环境下太阳能技术研发、产品制造与应用。</div>
                </div>
                <div class="hcell xuesantxt"  >
                    <div><img :src="xs4"></div>
                    <div class="xuesantitl">热心回馈社会</div>
                    <div class="xuesannote">树立家国情怀，强化企业家工匠精神，做专事业、做精产品、做优工作。感恩回馈社会，捐款、捐物，垫资脱贫攻坚1480万元。</div>
                </div>
                -->
            </div>
            </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="body1660 htable" style="">
                <div class="hcell" style="width: 130px;text-align: left;">
                    <div><span class="btLine">辉腾</span>优势</div>
                </div>
                <div class="hcell" style="width: 1400px;">
                    <div v-for="(n,i) of Math.ceil((rowsAdvan.length)/2)  " :key="'item'+rowsAdvan[2*i].tb_id">
                        <div class="htable" style="width: 100%;margin-bottom: 40px;" >
                            <div class="hcell" style="width: 600px;">
                                <img :src="rowsAdvan[2*i].tb_image"  style="display: flex">
                            </div>
                            <div  class="hcell" style="width: 800px;background-color:#ededed;display: flex;">
                                <div style="margin-left: 50px;text-align: left;width: 680px;margin-top:100px;">
                                    <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                                        {{rowsAdvan[2*i].tb_title}}
                                    </div>
                                    <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                                        {{rowsAdvan[2*i].tb_summary}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="htable" style="width: 100%;margin-bottom: 40px;" v-if="rowsAdvan[2*i+1]">
                            <div  class="hcell" style="width: 800px;background-color:#ededed;display: flex;">
                                <div style="margin-left: 50px;text-align: left;width: 680px;margin-top:80px;">
                                    <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                                        {{rowsAdvan[2*i+1].tb_title}}
                                    </div>
                                    <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                                        {{rowsAdvan[2*i+1].tb_summary}}
                                    </div>
                                </div>
                            </div>
                            <div class="hcell" style="width: 600px;">
                                <img :src="rowsAdvan[2*i+1].tb_image"  style="display: flex">
                            </div>
                        </div>
                    </div>
                </div>
                <div  class="hcell" style="width: 130px;">
                </div>
            </div>
        </section>

       <!-- <section style="margin-top: 90px;">
            <div class="body1660 htable" style="">
                <div class="hcell" style="width: 130px;text-align: left;">
                    <div><span class="btLine">辉腾</span>优势</div>
                </div>
                <div class="hcell" style="width: 600px;">
                        <img :src="ysp1"  style="display: flex">
                </div>
                <div  class="hcell" style="width: 800px;background-color:#ededed;display: flex;">
                    <div style="margin-left: 50px;text-align: left;width: 680px;margin-top:100px;">
                        <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                            专注弱光高效光伏研发9年
                        </div>
                        <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                            依托具有自主知识产权的核心技术，加工制造控制器、小功率逆变器等关键控制部件，采取轻资产运作方式，委外定点加工为太阳能路灯、光伏发电配套的智慧灯杆、大功率超导LED灯具等机电类设备及产品。
                        </div>
                    </div>
                </div>
                <div  class="hcell" style="width: 130px;">
                </div>
            </div>
        </section>
        <section style="margin-top: 40px;">
            <div class="body1400 htable" style="">
                <div  class="hcell" style="width: 800px;background-color:#ededed;display: flex;">
                    <div style="margin-left: 50px;text-align: left;width: 680px;margin-top:80px;">
                        <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                            专业的研发团队
                        </div>
                        <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                            公司秉承创新是第一推进力理念，坚持经营理念、关键技术、产品研发、运营管理和商业模式持续创新升级。邀请专家、学者作专业顾问，从前沿市场、从发达城市的新思维、新理念、新模式上协助公司优化管理模式，招揽优秀人才，升级管理平台。积极拓展市场领域，加速推动移动电源、移动电磁炉等新产品落地应用。

                        </div>
                    </div>
                </div>
                <div class="hcell" style="width: 600px;">
                    <img :src="ysp2"  style="display: flex">
                </div>
            </div>
        </section>

        <section style="margin-top: 40px;">
            <div class="body1400 htable" style="">
                <div class="hcell" style="width: 600px;">
                    <img :src="ysp3"  style="display: flex">
                </div>
                <div  class="hcell" style="width: 800px;background-color:#ededed;display: flex;">
                    <div style="margin-left: 50px;text-align: left;width: 680px;margin-top:100px;">
                        <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                            全球累计15万套成功案例
                        </div>
                        <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                            截至2020年底，公司光伏发电站销量突破1385座，高效智能太阳能路灯销量突破30万套，辉腾能源在全球布局下游生态链，为客户提供开发、融资、设计、施工、运维等一站式系统集成解决方案。
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 40px;">
            <div class="body1400 htable" style="">
                <div  class="hcell" style="width: 800px;background-color:#ededed;display: flex;">
                    <div style="margin-left: 50px;text-align: left;width: 680px;margin-top:80px;">
                        <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                            资质荣誉
                        </div>
                        <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                            公司现已拥有自主知识产权专利技术147项，软件著作权15项，国家及省部级资质荣誉100余项，全面通过GB/T29490知识产权管理体系、ISO9001质量管理体系、OHSAS180001职业健康安全管理体系认证、ISO14001环境管理体系；公司具备电力总承包、机电总承包、城市道路照明专项承包等资质。

                        </div>
                    </div>
                </div>
                <div class="hcell" style="width: 600px;">
                    <img :src="ysp4"  style="display: flex">
                </div>
            </div>
        </section>-->

        <section style="margin-top: 80px;"></section>
    </div>
</template>

<script>
    import myhead  from  '../components/myhead.vue'
    import 'video.js/dist/video-js.css'
    import {videoPlayer} from 'vue-video-player'
    import { listTopicBases,topicBases } from "../hts/api.js"

    export default {
        name: "indexbody",

        components: {
            myhead,
            videoPlayer,
        },
        data() {
            return {
                param: {
                    pageindex: 5,
                },
                xs1: require('../assets/xs1.png'),
                xs2: require('../assets/xs2.png'),
                xs3: require('../assets/xs3.png'),
                xs4: require('../assets/xs4.png'),
                ysp1: require('../assets/ysp1.png'),
                ysp2: require('../assets/ysp2.png'),
                ysp3: require('../assets/ysp3.png'),
                ysp4: require('../assets/ysp4.png'),

                playerOptions1: {
                    // videojs options
                    muted: false,
                    language: 'en',
                    playbackRates: [0.8, 1.0, 1.5, 2.0],
                    aspectRatio: '16:9',
                    loop: false,
                    sources: [{
                        type: "video/mp4",
                         src: require('../assets/hiten1.mp4')
                    }],
                    poster: require('../assets/aboutv1.png'),
                },
                res:{},
                businessData:[],
                rowsIdeas:[],
                rowsAdvan:[],
            }
        },
        mounted() {
            this.getAboutgs()
            this.getList()
        },
        methods: {
            getList () {
                // console.log(this.id)
                listTopicBases({type:'newWebsite:[businessData,developmentIdeas,advantage]', page: this.param.page, rows: this.param.rows })
                    .then(resData => {
                        this.businessData=resData.data.businessData
                        this.rowsIdeas=resData.data.developmentIdeas
                        this.rowsAdvan=resData.data.advantage
                        //alert(res.data.rows[0].tb_content)
                        //alert(data.rows[0].tb_content)
                    })
                    .catch()
            },
            getAboutgs () {
                topicBases({ ids: '[449]' })
                    .then(resData => {
                        this.res=resData.data['449'][0]
                    })
                    .catch()
            },

        },
    }
</script>

<style lang="css" scoped>
    .about {
        width: 100%;
    }
    .banneraabout{
        background-image: url('~assets/about.png');
        height: 600px;
    }
    .xuesan{
        background-image: url('~assets/xuesan.png');
        height: 650px;
    }
    .xuesantxt{
        width: 360px;
        color: white;
        /*border: 1px solid #d7000f;*/
        padding-left: 30px;
        padding-right: 30px;
    }
    .xuesantitl{
        font-size: 26px;
        line-height: 42px;
        font-weight: bold;
        margin-top: 24px;
    }
    .xuesannote{
        font-size: 14px;
        line-height: 28px;
        text-align: left;
        padding:0px 40px;
        margin-top: 20px;
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 20px;

    }

    .zzcp{
        background-color: #f6f6f6;
        height: 200px;
        width: 233px;
        justify-content: flex-end;
        /*border: 1px solid #d7000f;*/
    }
    .zzcp1{
        margin-left: 50px;
        margin-top: 50px;
        text-align: left;
        border-right:1px solid #d2d2d2;
    }
    .zzcp2{
        font-size: 14px;
    }
    .zzcp3{
        font-size: 40px;
        line-height: 63px;
        color: #d7000f;
    }
</style>

